<template>
  <main class="tour-payment w-100">
    <section class="tour-payment__container">
      <h1 class="title tour-payment__title">{{ $t("TOUR_ORDER.METHODS_OF_PAYMENT") }}</h1>
      <p class="text tour-payment__text">{{ $t("TOUR_ORDER.TOUR_PAYMENT_TXT") }}</p>

      <form class="tour-payment__form" action>
        <div class="tour-payment__item">
          <label class="label tour-payment__label">{{ $t("TOUR_ORDER.TICKET_NUMBER") }}</label>
          <input class="input tour-payment__input" type="text" />
        </div>

        <div class="tour-payment__item">
          <label class="label tour-payment__label">{{ $t("TOUR_ORDER.VALIDITY") }}</label>
          <div class="tour-payment__wrapper">
            <div class="dropdown">
              <select class="dropdown__select" name id>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
              </select>
              <button class="button dropdown__button">{{ $t("COMMON.SELECT") }}</button>
              <div class="dropdown__list">
                <a class="link dropdown__option" href="#" data-value="1">One</a>
                <a class="link dropdown__option" href="#" data-value="2">Two</a>
                <a class="link dropdown__option" href="#" data-value="3">Three</a>
                <a class="link dropdown__option" href="#" data-value="4">Four</a>
              </div>
            </div>
            <div class="dropdown">
              <select class="dropdown__select" name id>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
              </select>
              <button class="button dropdown__button">{{ $t("COMMON.SELECT") }}</button>
              <div class="dropdown__list">
                <a class="link dropdown__option" href="#" data-value="1">One</a>
                <a class="link dropdown__option" href="#" data-value="2">Two</a>
                <a class="link dropdown__option" href="#" data-value="3">Three</a>
                <a class="link dropdown__option" href="#" data-value="4">Four</a>
              </div>
            </div>
          </div>
        </div>

        <div class="tour-payment__item">
          <label class="label tour-payment__label">{{ $t("TOUR_ORDER.DIGITS_ON_CARD") }}</label>
          <input class="input tour-payment__input" type="text" />
        </div>

        <div class="tour-payment__item">
          <label class="label tour-payment__label">{{ $t("TOUR_ORDER.CARDHOLDER_NAME") }}</label>
          <input class="input tour-payment__input" type="text" />
        </div>

        <div class="tour-payment__item">
          <label class="label tour-payment__label">{{ $t("COMMON.ID") }}</label>
          <input class="input tour-payment__input" type="text" />
        </div>
      </form>
      <router-link  :to="{ name: 'order-details', params: { transaction_id: transactionId, phone_number: phoneNumber } }" tag="a" class="link tour-payment__link">
        {{ $t("COMMON.CONTINUE") }}
      </router-link>
      <!-- <a class="link tour-payment__link" href="tour-order-2.html">{{ $t("COMMON.CONTINUE") }}</a> -->

      <p class="text tour-payment__text-bottom">{{ $t("TOUR_ORDER.TOUR_PAYMENT_BOTTOMTXT") }}</p>
    </section>
  </main>
</template>

<script>
export default {
  name: "TourOrder1",
  computed: {
    transactionId() {
      return localStorage.getItem("transactionId");
    },
    phoneNumber() {
      return localStorage.getItem("phone_number");
    }
  }
};
</script>